<template>
  <div>
    <v-layout>
      <v-btn icon class="primary--text mt-1 mr-2" :to="`/`" router exact>
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>SSH Keys</page-title>

      <div class="ml-4 mt-1 mb-2">
        <v-btn class="primary--text mr-3" text @click="refresh()">
          <v-icon class="mr-1">refresh</v-icon>
          Refresh
        </v-btn>        
        <create-user-SSH-key></create-user-SSH-key>
      </div>
    </v-layout>

    <v-layout>
      <page-subtitle
        >Create, view and manage your public SSH keys.
        Learn more about ssh aaccess in the official
        <a
          href="https://docs.synpse.net/synpse-core/devices/ssh"
          class="ml-1"
          target="_blank"
          rel="noopener"
          >documentation</a
        >.
        
        </page-subtitle>
    </v-layout>

    <!-- delete confirmation modal -->
    <v-dialog v-model="modal" max-width="500px">
      <v-card>
        <v-card-title>Confirmation Required</v-card-title>
        <v-card-text>
          Are you sure want to delete ssh key '{{ removingUserSSHKey.name }}'?
        </v-card-text>
        <v-card-actions>
          <v-btn text v-on:click.native="modal = false" class="primary">Cancel</v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn v-on:click.native="deleteUserSSHKey(removingUserSSHKey)" text class="primary--text">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout class="mt-2">
      <div class="tbcard">
        <div v-if="!loading && userSSHKeys != undefined && userSSHKeys.length === 0">
          <v-container fill-height align-content-space-around>
            <v-layout row justify-center>
              <v-flex xs4 class="text-md-center mt-5">
                <span class="headline">No SSH Keys</span>
                <span class="subheading">
                  <page-subtitle class="text-md-center">
                    SSH keys are used to authenticate cia ssh commands.
                  </page-subtitle>
                </span>
              </v-flex>
            </v-layout>
          </v-container>
        </div>

        <v-card v-else outlined>
          <v-data-table
            :key="tableKey"
            :headers="headers"
            :items="userSSHKeys"
            :search="e3"
          >
            <template v-slot:[`item.name`]="{ item }">
                {{ item.name }}
            </template>

             <template v-slot:[`item.key`]="{ item }">
              <v-tooltip top :key="item.name">
                <template v-slot:activator="{ on: tooltip }">
                  <span v-on="{ ...tooltip }">
                    {{ item.key && item.key.substring(0,30)+"...." }}
                  </span>
                </template>
                <span>{{ item.key }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              <v-tooltip top :key="item.name">
                <template v-slot:activator="{ on: tooltip }">
                  <span v-on="{ ...tooltip }">
                    {{ item.createdAt | ago }}
                  </span>
                </template>
                <span>{{ item.createdAt | date }}</span>
              </v-tooltip>
            </template>

             <template v-slot:[`item.lastTimeUsed`]="{ item }">
              <v-tooltip top :key="item.name">
                <template v-slot:activator="{ on: tooltip }">
                  <span v-on="{ ...tooltip }">
                    {{ item.lastTimeUsed | ago }}
                  </span>
                </template>
                <span>{{ item.lastTimeUsed | date }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu class="items" top left transition="v-slide-y-transition">
                <template v-slot:activator="{ on: on }">
                  <v-btn
                    icon
                    v-on="on"
                    :class="
                      $store.state.theme === 'light' ? 'secondary--text' : ''
                    "
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item @click="removeUserSSHKeyIntent(item)">
                    <v-list-item-title>delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>

<script>
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';
import CreateUserSSHKey from './CreateUserSSHKey.vue';

export default {
  components: {
    PageSubtitle,
    PageTitle,
    CreateUserSSHKey,
  },
  data() {
    return {
      tableKey: '',
      e3: null,
      headers: [
        { text: 'Name', align: 'left', value: 'name', sortable: true },
        { text: 'Key', value: 'key', align: 'left' },
        { text: 'Created', value: 'createdAt', align: 'left' },
        { text: 'Last used', value: 'lastTimeUsed', align: 'left' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ],
      modal: false,
      removingUserSSHKey: {
        id: '',
        name: ''
      }
    }
  },

  computed: {
    loading() {
      return this.$store.state.iam.loading
    },
    userSSHKeys() {
      return this.$store.state.iam.userSSHKeys
    },
  },

  mounted() {
    this.refresh()
  },

  methods: {
    refresh() {
      this.$store.dispatch('ListUserSSHKeys')
    },
    removeUserSSHKeyIntent (removingUserSSHKey) {
      // assigning removing input val
      this.removingUserSSHKey = removingUserSSHKey
      this.modal = true
    },
    deleteUserSSHKey () {
      this.$store.dispatch('DeleteUserSSHKey', this.removingUserSSHKey).then(() => {
        this.refresh()
        // closing confirmation modal
        this.modal = false
      })
    }
  }
}
</script>